import React from 'react'
// Utils
import { newsLetterQuery } from './NewsLetter.utils'
// Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Heading } from '../../../atoms'
import HubspotForm from '../HubspotForm'

const NewsLetter: React.FC = () => {
  const {
    newsLetter: { headline, hubspotForm, whiteText, backgroundColor },
  } = newsLetterQuery()
  return (
    <Box as={'section'} id={'newsletter-signup-form'} my={[60, 80, 120]}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Box borderRadius={'medium'} bg={backgroundColor ? backgroundColor.hex : 'white'} p={[6, 7, 8]}>
              <Row center={'xs'}>
                <Col xs={12} md={10}>
                  {!!headline && (
                    <Heading as={'h5'} textAlign={'center'} color={whiteText ? 'white' : undefined}>
                      {headline}
                    </Heading>
                  )}
                </Col>
              </Row>
              {!!hubspotForm && (
                <Box mt={[5, 6, 7]}>
                  <Row center={'xs'}>
                    <Col xs={12} md={6}>
                      <HubspotForm
                        forNewsletter
                        region={hubspotForm?.region}
                        portalId={hubspotForm?.portalId}
                        formId={hubspotForm?.formId}
                      />
                    </Col>
                  </Row>
                </Box>
              )}
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default NewsLetter
