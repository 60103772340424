import { useStaticQuery, graphql } from 'gatsby'
import { HubspotForm } from '../../../types/items'

interface NewsLetterQuery {
  newsLetter: {
    headline: string
    backgroundColor: {
      hex: string
    }
    whiteText: boolean
    hubspotForm?: HubspotForm
  }
}

export const newsLetterQuery = (): NewsLetterQuery => {
  return useStaticQuery<NewsLetterQuery>(graphql`
    query {
      newsLetter: datoCmsGlobal {
        headline: nlsHeadline
        backgroundColor: nlsBackgroundColor {
          hex
        }
        whiteText: nlsWhiteText
        hubspotForm: nlsHubspotForm {
          ...hubspotForm
        }
      }
    }
  `)
}
