import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PaginationContextProps, PublicationProps } from '../../types/pages'
import { ArticlePreviewWithAuthorAndCategoriesProps, BlogLandingProps, CategoryProps } from '../../types/blog'
// Components
import { Link } from 'gatsby'
import { Layout, Seo } from '../../components/commons'
import { Box, Heading, Image } from '../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { BlogLandingHero, ArticlesHeaderWithCategories, BlogPaginatedArticles } from '../../components/blog'
import { ArticleCard, ArticleCardFull } from '../../components/blog/commons'
import NewsLetter from '../../components/commons/NewsLetter'

interface DataQuery {
  blogLanding: {
    pageInfo: PageInfoProps
    locale: string
    meta: PublicationProps
  } & BlogLandingProps
  featuredCategoriesArticlesList: {
    nodes: ArticlePreviewWithAuthorAndCategoriesProps[]
  }
  categoryList: {
    nodes: CategoryProps[]
  }
  articlesList: {
    nodes: ArticlePreviewWithAuthorAndCategoriesProps[]
  }
}

type PageContext = PaginationContextProps

const Blog: React.FC<PageProps<DataQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const {
    blogLanding: {
      pageInfo,
      locale,
      meta,
      headlinerArticle,
      headlinerBackgroundImage,
      headlinerBackgroundColor,
      featuredArticles,
      resourcesHeadline,
      resources,
      showNewsLetterSection,
      featuredCategoriesTitle,
      allArticlesLabel,
      articlesListLabel,
      paginationNextLabel,
      paginationPreviousLabel,
    },
    featuredCategoriesArticlesList: { nodes: featuredCategoriesArticles },
    categoryList: { nodes: categories },
    articlesList: { nodes: articles },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />

      <Box pt={[100, 120]}>
        {currentPage === 1 && (headlinerArticle || (featuredArticles && featuredArticles.length > 0)) && (
          <Grid>
            <Row>
              <Col xs={12}>
                {/* Headliner story */}
                {headlinerArticle && (
                  <Box mb={6}>
                    <BlogLandingHero
                      article={headlinerArticle}
                      bgImage={headlinerBackgroundImage}
                      bgColor={headlinerBackgroundColor}
                    />
                  </Box>
                )}
                {/* Featured articles */}
                {featuredArticles && featuredArticles.length > 0 && (
                  <Box>
                    <Row>
                      {featuredArticles.map((fa, i) => (
                        <Col key={fa.id} xs={12} sm={4}>
                          <Box mb={i + 1 < featuredArticles.length ? [7, 0] : undefined}>
                            <ArticleCard data={fa} />
                          </Box>
                        </Col>
                      ))}
                    </Row>
                  </Box>
                )}
              </Col>
            </Row>
          </Grid>
        )}

        {currentPage === 1 && resources && resources.length > 0 && (
          <Box as={'section'} my={[80, 80, 120]}>
            <Grid>
              <Row>
                <Col xs={12}>
                  {!!resourcesHeadline && (
                    <Box mb={7}>
                      <Heading as={'h4'}>{resourcesHeadline}</Heading>
                    </Box>
                  )}

                  <Box>
                    <Row>
                      {resources.map((r, i) => (
                        <Col key={r.id} xs={12} sm={6} md={4}>
                          <Box mb={i + 1 < resources.length ? [5, 7, 0] : undefined}>
                            {r.comingSoon ? (
                              <Box>
                                <Image alt={r.cover.alt || r.title} image={r.cover.gatsbyImageData} />
                              </Box>
                            ) : (
                              <Link to={`/${r.pageInfo.slug}`} style={{ display: 'block' }}>
                                <Image alt={r.cover.alt || r.title} image={r.cover.gatsbyImageData} />
                              </Link>
                            )}
                          </Box>
                        </Col>
                      ))}
                    </Row>
                  </Box>
                </Col>
              </Row>
            </Grid>
          </Box>
        )}

        {currentPage === 1 && featuredCategoriesArticles.length > 0 && (
          <Box as={'section'} my={[80, 80, 120]}>
            <Grid>
              <Row>
                <Col xs={12}>
                  {!!featuredCategoriesTitle && (
                    <Box mb={7}>
                      <Heading as={'h4'}>{featuredCategoriesTitle}</Heading>
                    </Box>
                  )}
                  <Box>
                    <Row>
                      {featuredCategoriesArticles.map((fca, i) => (
                        <Col key={fca.id} xs={12} sm={i === 0 ? 12 : 4}>
                          <Box
                            mb={i === 0 ? [7, 8, 10] : i + 1 < featuredCategoriesArticles.length ? [7, 0] : undefined}
                          >
                            {i === 0 ? <ArticleCardFull data={fca} /> : <ArticleCard data={fca} />}
                          </Box>
                        </Col>
                      ))}
                    </Row>
                  </Box>
                </Col>
              </Row>
            </Grid>
          </Box>
        )}

        {currentPage === 1 && showNewsLetterSection && <NewsLetter />}

        {articles.length > 0 && (
          <Box as={'section'}>
            <ArticlesHeaderWithCategories
              title={articlesListLabel}
              blogLandingPageInfo={pageInfo}
              allLabel={allArticlesLabel}
              categories={categories}
              isAll={true}
            />
            <BlogPaginatedArticles
              articles={articles}
              prevLabel={paginationPreviousLabel}
              previousPagePath={previousPagePath}
              nextLabel={paginationNextLabel}
              nextPagePath={nextPagePath}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </Box>
        )}
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!, $featuredCategories: [String!]!) {
    blogLanding: datoCmsBlog {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      headlinerArticle {
        ...articlePreviewWithAuthorAndCategoriesFragment
      }
      headlinerBackgroundImage {
        alt
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      headlinerBackgroundColor {
        hex
      }
      featuredArticles {
        ...articlePreviewWithAuthorAndCategoriesFragment
      }
      resourcesHeadline
      resources {
        ...resourceCoverFragment
      }
      showNewsLetterSection
      featuredCategoriesTitle
      allArticlesLabel
      articlesListLabel
      paginationPreviousLabel
      paginationNextLabel
    }
    featuredCategoriesArticlesList: allDatoCmsArticle(
      filter: { categories: { elemMatch: { id: { in: $featuredCategories } } } }
      limit: 4
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        ...articlePreviewWithAuthorAndCategoriesFragment
      }
    }
    categoryList: allDatoCmsCategory(sort: { fields: name }) {
      nodes {
        ...categoryFragment
      }
    }
    articlesList: allDatoCmsArticle(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        ...articlePreviewWithAuthorAndCategoriesFragment
      }
    }
  }
`

export default Blog
